import { useState } from "react";
import FooterComponent from "../components/FooterComponent";
import NavbarComponent from "../components/NavbarComponent";
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate

const Register = () => {
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email_adress, setEmail_Adress] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [withdrawalPolicyAccepted, setWithdrawalPolicyAccepted] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if both checkboxes are accepted
    if (!termsAccepted || !withdrawalPolicyAccepted) {
      setError(
        "You must accept the Terms and Conditions and the Withdrawal Policy."
      );
      return;
    }

    const userdetails = { first_name, last_name, email_adress, password };

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/userdetails/createAccount`,
      {
        method: "POST",
        body: JSON.stringify(userdetails),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();

    if (!response.ok) {
      setError(json.error);
    }
    if (response.ok) {
      setFirst_Name("");
      setLast_Name("");
      setEmail_Adress("");
      setPassword("");
      setError(null);

      // Store the token in local storage
      localStorage.setItem("token", json.token);
      console.log("Account created");
      navigate("/");
    }
  };

  return (
    <div>
      <NavbarComponent />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
        }}
      >
        <form
          className="create"
          onSubmit={handleSubmit}
          style={{
            border: "1px solid #ccc", // Optional: border for visibility
            padding: "20px", // Padding for form
            borderRadius: "5px", // Rounded corners
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: shadow
            backgroundColor: "#fff", // Optional: background color
            display: "flex", // Use flexbox for form layout
            flexDirection: "column", // Stack children vertically
            gap: "10px", // Spacing between fields
          }}
        >
          <h3>Register</h3>

          <label>First Name:</label>
          <input
            type="text"
            onChange={(e) => setFirst_Name(e.target.value)}
            value={first_name}
            style={{ width: "100%" }} // Full width input
          />

          <label>Last Name:</label>
          <input
            type="text"
            onChange={(e) => setLast_Name(e.target.value)}
            value={last_name}
            style={{ width: "100%" }} // Full width input
          />

          <label>Email Address:</label>
          <input
            type="email"
            onChange={(e) => setEmail_Adress(e.target.value)}
            value={email_adress}
            style={{ width: "100%" }} // Full width input
          />

          <label>Password:</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            style={{ width: "100%" }} // Full width input
          />

          <div>
            <label>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              I agree to the{" "}
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </label>
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                checked={withdrawalPolicyAccepted}
                onChange={(e) => setWithdrawalPolicyAccepted(e.target.checked)}
              />
              I have read and accept the{" "}
              <a href="/withdrawal" target="_blank" rel="noopener noreferrer">
                Withdrawal Policy
              </a>
            </label>
          </div>

          {/* Added the 7-day trial text */}
          <p style={{ fontSize: "14px", color: "#333", marginTop: "10px" }}>
            Enjoy a <strong>7-day free trial</strong> to experience all the benefits of Techie GPT before being charged.
          </p>

          <button style={{ marginTop: "10px" }}>Create Account</button>
          <Link to="/login" style={{ marginTop: "10px", textAlign: "center" }}>
            Already have an account? Login here
          </Link>
          {error && <div className="error">{error}</div>}
        </form>
      </div>
      <FooterComponent />
    </div>
  );
};

export default Register;
