import { Link } from "react-router-dom";
import FooterComponent from "../components/FooterComponent";
import NavbarComponent from "../components/NavbarComponent";

const PricingPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <NavbarComponent />
      <div style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
      }}>
        <div style={{
          maxWidth: '600px',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
        }}>
          <h1 style={{ color: '#333' }}>Pricing</h1>
          <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
            Welcome to Techie GPT! We offer our cutting-edge AI support for
            just <strong>€10 per month (including taxes)</strong>. With this subscription, you'll have access to
            reliable and efficient assistance for all your technical queries.
          </p>
          <h2 style={{ color: '#007bff' }}>Plan Details:</h2>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li><strong>Monthly Fee:</strong> €10 (including taxes)</li>
            <li><strong>Word Limit:</strong> 50,000 words per month</li>
            <li><strong>Free Trial:</strong> Enjoy a <strong>7-day free trial</strong> before your subscription starts</li>
          </ul>
          <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
            Our Tech Support AI is designed to help you navigate your tech challenges with
            ease. Whether you need quick answers or detailed guidance, we’ve got
            you covered!
          </p>
          <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
            Start your journey with us today and experience the
            benefits of AI-powered support at an affordable price.
          </p>
          <Link to="/register" style={{
            display: 'inline-block',
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            textDecoration: 'none',
            borderRadius: '5px',
            transition: 'background-color 0.3s',
          }}>
            Register Now
          </Link>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default PricingPage;
