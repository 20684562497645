import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";

const Home = () => {
  return (
    <div>
      <NavbarComponent />
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <div>
          <h1 style={{ fontSize: "2rem" }}>
            Techie GPT: Your AI-Powered Assistance
          </h1>
          <p style={{ maxWidth: "600px", textAlign: "left" }}>
            Welcome to Techie GPT! Our innovative service provides you with fast and reliable support for all your technical questions and issues. Utilizing cutting-edge AI technology, we are available 24/7 to help you find solutions, provide guidance, and answer your inquiries. Whether it's software problems, hardware questions, or general tech concerns, our goal is to offer you straightforward and effective assistance. Trust Techie GPT to help you overcome your technical challenges!
          </p>
        </div>
      </div>

      {/* New text about the free trial and ad-based AI model */}
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#007bff" }}>
          Enjoy a Free 7-Day Trial!
        </h2>
        <p style={{ maxWidth: "600px", textAlign: "left", margin: "0 auto" }}>
          Start using Techie GPT with a <strong>7-day free trial</strong>! Experience all the benefits of AI-powered tech support, absolutely free for the first week. After the trial, you can choose to subscribe to one of our plans for continued access to top-notch assistance.
        </p>
        <p style={{ maxWidth: "600px", textAlign: "left", margin: "20px auto" }}>
          We also offer an <strong>ad-supported AI model</strong>. You can use the AI service for free, but with ads shown during your sessions. This allows you to continue using our platform without any subscription fees, while still receiving valuable assistance powered by our AI but won't feature our best models.
        </p>
      </div>

      {/* Buttons for navigation */}
      <Button variant="primary" href="authenticate" size="lg">
        Login
      </Button>{" "}
      <Button variant="primary" href="register" size="lg">
        Register
      </Button>{" "}
      <Button variant="success" href="/" size="lg">
        Chat
      </Button>{" "}

      <FooterComponent />
    </div>
  );
};

export default Home;
