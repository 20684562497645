import React, { useEffect, useState } from "react";
import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";
import {
  Container,
  Card,
  Spinner,
  Alert,
  Button,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const AccountPage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/userdetails/user`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          localStorage.removeItem("token");
          window.location.href = "/login";
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);
        setUserData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteAccount = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/userdetails/delete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete account");
      }

      localStorage.removeItem("token");
      setUserData(null);
      alert("Account deleted successfully.");
    } catch (err) {
      setError(err.message);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleCancelSubscription = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/userdetails/cancel-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }

      alert("Subscription canceled successfully.");
    } catch (err) {
      setError(err.message);
    } finally {
      setShowCancelModal(false);
    }
  };

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleShowCancelModal = () => setShowCancelModal(true);
  const handleCloseCancelModal = () => setShowCancelModal(false);

  const handleSubscribe = () => {
    window.location.href = "/checkout"; // Navigate to checkout page
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <Container>
        <Alert variant="danger">Error: {error}</Alert>
      </Container>
    );
  }

  return (
    <div>
      <NavbarComponent />
      <Container className="my-4">
        <h1 className="text-center">Account Information</h1>
        {userData ? (
          <Card className="text-center">
            <Card.Header as="h5">User Details</Card.Header>
            <Card.Body>
              <Card.Title>
                {userData.first_name + " " + userData.last_name}
              </Card.Title>
              <Card.Text>
                <strong>Email:</strong> {userData.email_adress}
                <br />
                <strong>Subscribed:</strong>{" "}
                {userData.subscribed ? "Yes" : "No"}
                <br />
                <strong>Words Used This Month:</strong> {userData.words_used}
                <br />
                <br />
                <br />
                <strong>Free Trial: </strong>{" "}
                {userData.trial_start ? "Yes" : "No"}
                <br />
                <strong>Free Trial Words Used: </strong> {userData.trial_words}
              </Card.Text>
              {userData.subscribed ? (
                <Button
                  variant="warning"
                  onClick={handleShowCancelModal}
                  className="ms-2"
                >
                  Cancel Subscription
                </Button>
              ) : (
                <Button variant="success" onClick={handleSubscribe}>
                  Subscribe
                </Button>
              )}
              <Button
                variant="danger"
                onClick={handleShowDeleteModal}
                className="ms-2"
              >
                Delete Account
              </Button>
            </Card.Body>
            <Card.Footer className="text-muted">
              Last updated just now
            </Card.Footer>
          </Card>
        ) : (
          <p>No user data found.</p>
        )}
      </Container>

      {/* Modal for confirmation of account deletion */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Account Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account? This action cannot be
          undone. You won't be able to use the Tech Support AI anymore from this
          point on.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteAccount}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for confirmation of subscription cancellation */}
      <Modal show={showCancelModal} onHide={handleCloseCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Subscription Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel your subscription? This action cannot
          be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancelModal}>
            Cancel
          </Button>
          <Button variant="warning" onClick={handleCancelSubscription}>
            Cancel Subscription
          </Button>
        </Modal.Footer>
      </Modal>

      <FooterComponent />
    </div>
  );
};

export default AccountPage;
